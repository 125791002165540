import { FC, useEffect, useState } from 'react';
import FormBuilderBase from '../../components/form-builder/FormBuilderBase';
import { PlatformIcons } from '../../components/shared/icon/IconRender';
import { v4 as uuid } from 'uuid';
import { useTranslation } from 'react-i18next';
import { AdminLayout } from './Layout';
import { useCurrentClient, useCurrentTenantId } from '../../global-state/Clients';
import { useSearchParams } from 'react-router-dom';
import { FormType } from '../../models/FormTypes';
import i18n from 'i18next';

const FormBuilder: FC = () => {
  const currentClient = useCurrentClient((x) => x.value);
  const currentTenantId = useCurrentTenantId((x) => x.value);
  const { t } = useTranslation('form-builder');
  const [searchParams] = useSearchParams();
  const [formType, setFormType] = useState<(typeof FormType)[keyof typeof FormType] | undefined>(undefined);
  const [initialForm, setInitialForm] = useState({
    id: uuid(),
    type: formType,
    code: '',
    version: 1,
    iconCode: PlatformIcons.FORM,
    translations: { [i18n.language]: { title: t('untitled-template') } },
    sections: [],
    placeholders: [],
  });

  useEffect(() => {
    const formTypeQuery = searchParams.get('type');
    if (formTypeQuery) {
      const parsedFormType = Number(formTypeQuery) as (typeof FormType)[keyof typeof FormType];

      if (Object.values(FormType).includes(parsedFormType)) {
        setFormType(parsedFormType);
      } else {
        console.error('Invalid form type value:', formTypeQuery);
        setFormType(undefined);
      }
    } else {
      setFormType(undefined);
    }
  }, [searchParams]);

  useEffect(() => {
    setInitialForm((prev) => ({
      ...prev,
      type: formType,
    }));
  }, [formType]);

  return (
    <AdminLayout>
      <FormBuilderBase
        key={initialForm.type}
        initialForm={initialForm}
        topNavHeadingKey="form-builder:heading.form-builder"
        failsafeKey="form"
        breadCrumbs={[
          {
            name: t('breadcrumb.form-builder'),
            path: currentClient?.id ? `/clients/${currentClient.id}/organisation#forms` : `/builder/${currentTenantId}#form-builder`,
          },
        ]}
      />
    </AdminLayout>
  );
};

export default FormBuilder;
